//IMPORT
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Roboto:wght@400;500;700;900&display=swap');
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

@font-face {
  font-family: 'GNOGEN';
  src: url('../font/GNOGEN.otf') format('opentype');
}

@font-face {
  font-family: 'GNOGEN_2';
  src: url('../font/GNOGEN_2.otf') format('opentype');
}

// GENERAL

body {
  overflow-x: hidden;
}

.gnogen-yellow {
  color: #ffb400;
}

.gnogen-text {
  font-size : 25px;
  font-family : 'Roboto';
}

.text-gnogen-2 {
  font-family : 'GNOGEN_2';
}

.text-roboto {
  font-family : 'Roboto';
}

.text-lato {
  font-family : 'Lato';
}

.fs-next-mint {
  font-size: 3rem;
}
@include media-breakpoint-up(md) {
  .fs-next-mint {
    font-size: 5rem;
  }
}
@include media-breakpoint-up(xl) {
  .fs-next-mint {
    font-size: 6rem;
  }
}


.fs-90 {
  font-size: 90px;
} .fs-75 {
  font-size: 75px;
} .fs-59 {
  font-size: 59px;
} .fs-45 {
  font-size: 45px;
} .fs-25 {
  font-size: 25px;
} .fs-20 {
  font-size: 20px;
}

.font-weight-medium {
  font-weight: 500;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.p-40 {
  padding: 40px;
}

.gnogen-text-shadow {
  text-shadow: 0px 3px 6px #0000004a;
}

.gnogen-button {
  border: #ffb400 3px solid;
  background-color: white;
  font-size: 16px;
  border-radius: 30px;
  padding: 7px 25px 7px 25px;
  font-family: 'Lato';
  font-weight: 600;
}

.gnogen-button:hover {
  color: white;
  background-color: #ffb400;
}

// NAVBAR

.gnogen-navbar {
  text-align: center;
  .navbar-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .nav-item {
      font-family: 'Roboto';
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.25rem;
      text-shadow: 0px 2px 5px #00000087;
      display: inline-block;
      a {
        color: #212529;
        transition: 0.2s;
        text-decoration: none;
      }
      a:hover {
        text-shadow: 0px 2px 7px #00000087;
        text-decoration: none;
      }
    }
  }
}

.gnogen-logo {
  width: 200px;
}

// HOME

.mint-information {
  font-family: 'GNOGEN_2';
  font-size: 25px;
}

.text-mint-now {
  display: inline-block;
  text-decoration: none;
  transform-origin: 38px 109px;
  transform: rotate(90deg);
  font-size: 100px;
  white-space: nowrap;
  text-shadow: 3px 0px 3px #00000029;
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: white;
  font-family: Lato;
  cursor: pointer;
}

.text-mint-now:hover {
  text-decoration: none;
  -webkit-text-fill-color: #ffb400;
}

.text-mint-now-md {
  display: inline-block;
  text-decoration: none;
  font-size: 5rem;
  white-space: nowrap;
  text-shadow: 3px 0px 3px #00000029;
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: white;
  font-family: Lato;
  cursor: pointer;
}

.text-mint-now-md:hover {
  text-decoration: none;
  -webkit-text-fill-color: #ffb400;
}

.gnogen-video-container {
  width: 322px;
  height: 425px;
  margin-left: auto;
  margin-right: auto;
  .gnogen-video {
    width: 322px;
  }
}
@include media-breakpoint-up(sm) {
  .gnogen-video-container {
    width: 422px;
    height: 564px;
    .gnogen-video {
      width: 422px;
    }
  }
}

// LOTTERY
.gnogen-lottery-title_container {
  max-height: 98px;
  margin: 0;
}

.gnogen-lottery-title {
  font-size : 135px;
  font-family: 'GNOGEN';
  text-shadow: 0px 3px 6px #0000007a;
}

.gnogen-rules-container {
  margin-top: 50px;
}

.gnogen-connect-button {
  border: #ffb400 3px solid;
  font-size: 16px;
  border-radius: 30px;
  padding: 7px 25px 7px 25px;
  font-family: 'Lato';
  font-weight: 600;
  margin-top: 20px;
}

.gnogen-connect-button:focus {
  box-shadow: none;
}

.gnogen-connect-button:hover {
  color: white;
  background-color: #ffb400;
}

.gnogen-text-rules {
  font-size : 20px;
  font-family : 'Roboto';
}

// UNLOCK

.unlock-card {
  min-width: 400px;
  border: #ffb400 3px solid;
  border-radius: 30px;
}

.unlock-icon {
  width: 35px;
}

.btn-connect_wrapper,
.btn-connect_noExtensionButtonWrapper {
  padding: 10px 10px 10px 20px !important;
  text-align: left !important;
  font-family : 'Roboto' !important;
  font-weight: 500 !important;
  margin-bottom: 20px !important;
  border-radius: 30px !important;
  box-shadow: 0 2px 4px rgba(0,0,0,.04)!important;
}

.btn-connect_wrapper:hover,
.btn-connect_noExtensionButtonWrapper:hover {
  color: white !important;
  background-color: #ffb400 !important;
}

// MINT

.gnogen-max-button {
  font-weight: 600;
  border: 2px solid black;
  border-radius: 10px;
}

.gnogen-mint-main-text {
  font-size: 50px;
}

.gnogen-mint-select_container {
  display: flex;
    align-items: center;
    height: 100%;
}

.gnogen-border-top {
  border-top: #ffb400 3px solid;
  min-width: 88%;
  margin: 0 auto;
}

.gnogen-mint-total-price {
  font-size: 50px;
  margin-top: 30px;
}

.gnogen-text-minting {
  display: inline-block;
    text-decoration: none;
    transform-origin: 5px 142px;
    transform: rotate(90deg);
    font-size: 100px;
    white-space: nowrap;
    text-shadow: 4px 0px 4px #0000004a;
    color: #ffb400;
    font-family: 'Lato';
}

// BUY

.gnogen-countdown-title {
  font-family: 'GNOGEN';
  font-size: 95px;
  color: black;
  text-shadow: 0px 3px 6px #0000007a;
}

.gnogen-countdown_container {
  .gnogen-countdown {
    font-family: 'GNOGEN_2';
    font-size: 95px;
    color: #66645e;
    text-shadow: 0px 3px 6px #0000007a;
  }
}

.gnogen-buy-info {
  font-size: 30px;
  font-weight: bold;
}

// FOOTER


.gnogen-footer {
  text-align: center;
  font-size: 14px;
  text-shadow: 0px 2px 5px #00000087;
  font-weight: 500;
  ul {
    padding-left: 0px;
    li {
      display: inline-block;
      margin-left: 2.5vw;
      margin-right: 2.5vw;
    }
  }
}