.modal-content {
    border: #ffb400 3px solid !important;
    .dapp-core-ui-component {

        .gnogen-sign-transaction_wrapper {
            font-family: 'Roboto';
            text-transform: uppercase;
            .gnogen-sign-transaction_closeBtn {
                border: #ffb400 3px solid;
                background-color: white;
                font-size: 16px;
                border-radius: 30px;
                padding: 7px 25px 7px 25px;
                font-family: 'Lato';
                font-weight: 600;
                text-transform: uppercase;
            }
            .gnogen-sign-transaction_closeBtn:hover {
                color: white;
                background-color: #ffb400;
            }
        }
    }
}